import {
    BannerNews,
    Miniatura,
    Detalle,
} from '../../assets/images';
import { TypeOfBean, NewsResultsGrid, FilterNews } from '../../components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import React from 'react';
import { Helmet } from 'react-helmet';
import './styles.css';
import { NavegableHeader, NavegableFooter } from '../../components';

const ResultsNews = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { category, date, search } = useParams();

  return (
    <>
        <NavegableHeader />
        <section id='News' className='alx-back-seccion'>
            <Helmet>
                <meta charSet='utf-8' />
                <title>News | Isadora Beans</title>
                <link rel='canonical' href={window.location.href} />
            </Helmet>
           
            <div className='container'>
                <div id="alx-div-top-results"></div>
                <div className='row'>

                    <div className="col-xs-12 col-md-12 col-lg-3 order-lg-last">
                        <FilterNews categoryId={category} fecha={date} texto={search} />
                    </div>

                    <div className="col-xs-12 col-md-12 col-lg-9 order-lg-first">
                            <NewsResultsGrid cantidad='9' categoryId={category} fecha={date} texto={search} />
                    </div>
                    
                    
                </div>
            </div>

        </section>

        <NavegableFooter />
        

    </>
  );
}

export default ResultsNews;
