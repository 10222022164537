import PropTypes from 'prop-types';
import styles from './styles';
import { RecipeSubTitle, RecipeList } from './';

const RecipeBarbacoaBeefTaco = ({ data }) => {
  return (
    <article className={styles.article}>
      <div className='text-center'>
        <RecipeSubTitle headline={data.recipes[1].title} id={data.recipes[1].id} m='lg:mb-10' />
      </div>
      <div className='justify-start gap-10 lg:flex'>
        <img
          src={data.recipes[1].img}
          alt={data.recipes[1].alt + ' recipe'}
          className={`${styles.barbacoaBeefTortillaFlautasImg()}`}
        />
        <div className='lg:-translate-x-[30%] xl:flex-shrink-0 2xl:translate-x-0'>
          {['Ingredients', 'Directions'].map((title, i) => (
            <RecipeList
              title={title}
              color={data.color}
              list={data.recipes[1].recipe[0][title.toLowerCase()]}
              key={i}
            />
          ))}
        </div>
      </div>
    </article>
  );
};

RecipeBarbacoaBeefTaco.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RecipeBarbacoaBeefTaco;
