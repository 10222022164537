import { useEffect } from 'react';
import { useFetch } from '../../utilities/hooks';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
    Recipes1,
    IngredientsSpoon,
    PreparationBowl,
    BannerNews,
} from '../../assets/images';
import {
    SearchBar,
    RelatedNews,
    ShareNavNews,
    Preloader,
    FilterNews,
} from '../../components';
import './styles.css';
import { NavegableHeader, NavegableFooter } from '../../components';

const SingleNews = () => {
    /*const BASE_URL = process.env.REACT_APP_API_BASE_URL;*/
    const BASE_URL = process.env.REACT_APP_API_BASE_URL_NEWS;
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { slug } = useParams();
    const { data } = useFetch(`${BASE_URL}/api/news/detail/${slug}`);

    useEffect(() => {
        if (data) {
            /*console.log(data);*/
        }
        else {
          // Si no hay datos, navegar a la página de error 404
          
        }
    }, [pathname, data]);

    const handleBack = () => {
        window.history.back();
    };



    return (
        <>
            <NavegableHeader />
            {data ? (

                !data.detail ? (
                   navigate('/404')
                ) : (
                <>
                    
                    <Helmet>
                        <meta charSet='utf-8' />
                        <title>{data.detail.titulo} | Isadora Beansddd</title>
                        <link rel='canonical' href={window.location.href} />
                        <meta name='keywords' content={data.detail.keywords} />
                        
                        <meta property='og:site_name' content='Isadora Beans'/>
                        <meta property='og:url' content={window.location.href} />
                        <meta property='og:type' content="article"/>
                        <meta property='og:title' content={data.detail.titulo} />
                        <meta property='og:image' content={BASE_URL +'/uploads/noticias/'+data.detail.imagen_mobile} />
                        <meta property='og:image:secure_url' content={BASE_URL+'/uploads/noticias/'+data.detail.imagen_mobile} />
                        <meta property='og:image:alt' content={data.detail.titulo} />

                        <meta name='twitter:card' content='summary_large_image' />
                        <meta name='twitter:title' content={data.detail.titulo} />
                        <meta name='twitter:image' content={BASE_URL+'/uploads/noticias/'+data.detail.imagen_mobile} />
                        <meta name='twitter:url' contnet={window.location.href} />
                        <meta name='twitter:site' content='Isadora Beans' />
                        <meta name='twitter:creator' content='Isadora Beans' />
                        <meta name='twitter:domain' content={window.location.hostname} />
                    </Helmet>
                    <section id='News' className='alx-back-seccion'>

                    <div className='container d-none d-md-block'>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <a onClick={handleBack} className='alx-btn-atras text-brownIs'>Back</a>
                            </div>
                        </div>
                    </div>

                    <div className='container'>
                        <div className='row'>
                            <div className="col-xs-12 col-md-12 col-lg-3 order-lg-last d-none d-lg-block">
                                <FilterNews />
                            </div>

                            <div className="col-xs-12 col-md-12 col-lg-9 order-lg-first">
                                <div className="alx-banner alx-imagen-detalle-news">
                                <img
                                    src={
                                        BASE_URL +
                                        '/uploads/noticias/' +
                                        data.detail.imagen_desk


                                        
                                    }
                                    className='alx-img-full d-none d-md-block'
                                    alt={data.detail.titulo}
                                />
                                <img
                                    src={
                                        BASE_URL +
                                        '/uploads/noticias/' +
                                        data.detail.imagen_mobile


                                        
                                    }
                                    className='alx-img-full d-md-none'
                                    alt={data.detail.titulo}
                                />
                                </div>

                                <h1 className='alx-color-cafe alx-bold au-intro mb-4'>
                                    {data.detail.titulo}
                                </h1>

                                <div className='row mb-4'>
                                    <div className="col-6 alx-bold alx-color-naranja">
                                        {data.detail.created_at.substring(0, 10)}
                                    </div>
                                    <div className="col-6">
                                        <div id="alx-cont-share">
                                            <ShareNavNews
                                                image={BASE_URL +
                                        '/uploads/noticias/' +
                                        data.detail.imagen_mobile}
                                            />
                                        </div>
                                    </div>
                                </div>

                                
                                <div className='mb-5'>
                                <div
                                    className='alx-detalle-news-cuerpo' 
                                    dangerouslySetInnerHTML={{
                                        __html: data.detail.cuerpo
                                    }}
                                ></div>

                                {(data.detail.link_externo != '' && data.detail.link_externo_text != '') ? 
                                    <a href={data.detail.link_externo}
                                        target='_blank' 
                                        className='alx-link-externo'>
                                        {data.detail.link_externo_text}
                                    </a>
                                : 
                                <div></div>}

                              
                                </div>

                                

                            </div>
                            
                            
                        </div>
                    </div>
                    <div className='container d-md-none'>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <a onClick={handleBack} className='alx-btn-atras text-brownIs'>Back</a>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-9'>
                                <RelatedNews
                                    categoryId={data.detail.categoria_id}
                                    newsId={data.detail.id}
                                />
                            </div>
                        </div>
                    </div>
                    </section>






                   
                </>
            )) : (
                <Preloader />
            )}
            <NavegableFooter />
        </>
    );
};

export default SingleNews;
