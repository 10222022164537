import { useState } from 'react';
import data from './data';
import styles from './styles';
import {
  RecipeBarbacoaBeefTaco,
  RecipeBarbacoaBeefTortillaFlautas,
  RecipeMoleChickenTaco,
  RecipeMoleChickenSliders,
  RecipePorkInGreenSalsaTaco,
  RecipePorkInGreenSalsaOnATostada, 
  RecipeRiceTomato, 
  RecipeRiceCorn, 
} from './';

const [barbacoa, mole, pork, tomato, corn] = data;
const recipes = [
  { text: 'barbacoa', bgColor: 'orange rounded-l-xl', clave: 'barbacoa' },
  { text: 'mole', bgColor: 'purple', clave: 'mole' },
  { text: 'pork', bgColor: 'green', clave: 'pork' }, 
  { text: 'rice tomato', bgColor: 'rojo', clave: 'tomato' }, 
  { text: 'rice corn', bgColor: 'amarillo rounded-r-xl', clave: 'corn' }, 
];

const RecipesAllSection = () => {
  const [selectedRecipe, setSelectedRecipe] = useState('barbacoa');
  return (
    <section className={styles.section}>
      <div className={styles.recipesSelector}>
        {recipes.map((recipe, i) => (
          <button
            key={i}
            onClick={setSelectedRecipe.bind(null, recipe.clave)}
            className={`${styles.recipesBtn} alx-button-filtrar-recetas boton-filtra-${recipe.clave} bg-recipes-colors-${
              recipe.bgColor
            } ${
              recipe.clave === selectedRecipe
                ? 'bg-opacity-100'
                : 'bg-opacity-50'
            }`}
          >
            {recipe.text}
          </button>
        ))}
      </div>
      <div className={`${styles[selectedRecipe]} ${styles[selectedRecipe+'ver']} ${styles.carousel}`}>
        <div className='min-w-full grupo-barbacoa'>
          <RecipeBarbacoaBeefTaco data={barbacoa} />
          <RecipeBarbacoaBeefTortillaFlautas data={barbacoa} />
        </div>
        <div className='min-w-full grupo-mole'>
          <RecipeMoleChickenTaco data={mole} />
          <RecipeMoleChickenSliders data={mole} />
        </div>
        <div className='min-w-full grupo-pork'>
          <RecipePorkInGreenSalsaTaco data={pork} />
          <RecipePorkInGreenSalsaOnATostada data={pork} />
        </div>
        <div className='min-w-full grupo-tomato'>
          <RecipeRiceTomato data={tomato} />
        </div>
        <div className='min-w-full grupo-corn'>
          <RecipeRiceCorn data={corn} />
        </div>
      </div>
    </section>
  );
};

export default RecipesAllSection;
