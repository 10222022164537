import PropTypes from 'prop-types';
import styles from './styles';

const RecipeSubTitle = ({ headline, m, id }) => {
  return (
    <>
      <h4
        className={styles.subTitle(m)}
        dangerouslySetInnerHTML={{ __html: headline }}
        id={id}
      ></h4>
    </>
  );
};

RecipeSubTitle.propTypes = {
  headline: PropTypes.string.isRequired,
  m: PropTypes.string.isRequired,
};

export default RecipeSubTitle;
