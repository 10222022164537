import { create } from 'zustand';
import Store from './Store';
import stores from './stores';

const getStoreList = (productName) =>
  Object.values(stores).map((store) => (
    <Store store={store} productName={productName} key={store.name} />
  ));

const useModalStore = create((set) => ({
  storeList: [],
  isModalOpen: false,
  updateModalState: (value) => set({ isModalOpen: value }),
  updateStoreList: (value) => set({ storeList: getStoreList(value) }),
}));

export default useModalStore;
