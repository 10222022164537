import React from 'react';
import { favicon } from '../assets/images';
import { Helmet } from 'react-helmet';
import { NavegableHeader, NavegableFooter } from '../components';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Notice Of Privacy | Mexican Foods | Isadora Beans</title>
        <link rel="canonical" href="https://mexicanfood.com/" />
        <meta
          name="description"
          content="México’s #1 Beans in a pouch. Isadora Beans are the tastiest, freshly made and vacuum-packed without preservatives. Try them on their own or add your personal touch."
        />
        <meta
          name="keywords"
          content="beans, mexico, isadora beans, food, fresh food, tasty, mexican food"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href={favicon} />
      </Helmet>
      <NavegableHeader />
      <section className="m-auto w-full lg:w-2/3 ">
        <div className="m-5 max-w-[650px] text-[#737373] lg:mt-20 lg:ml-12 [&_p]:mb-7 [&_p]:font-[100]  ">
          <h2 className="mt-12 mb-8 text-2xl font-bold text-[#00ae41] lg:text-3xl">
            Privacy Policy
          </h2>
          <p>
            The website [www.verdevallerecipes.com], (hereinafter "the Site") is
            owned and operated by PRODUCTOS VERDE VALLE S.A. DE C.V. (“Verde
            Valle,” “we,” or “us”). The Site provides information regarding
            products offered by Verde Valle and the ability for interested
            customers to request more information from Verde Valle regarding its
            products (the “Services”).
          </p>
          <p>
            Verde Valle is committed to protecting your privacy and utilizing
            technology that gives you a powerful and safe online experience.
            Please read carefully through all sections of this Privacy Policy.
            Your access to and use of the Site is subject to this Privacy Policy
            and all applicable laws. By submitting your contact information to
            receive more information from us through the Site, or accessing
            and/or otherwise using the Site, you consent to Verde Valle’s use of
            your Personal Data as set forth below in this Privacy Policy. If you
            do not agree to this Privacy Policy, then you may not use the Site
            and you should submit your contact information to the Site. This
            Privacy Policy may be changed by us from time to time without notice
            to you, and the governing version will be posted on the Site. Please
            review this Privacy Policy on a regular basis as your use of the
            Site will be governed by the then-current Privacy Policy
          </p>
          <p>
            Verde Valle recognizes the importance of protecting the privacy of
            our customers and the users of the Site. However, some uses of such
            information are required for us to conduct legitimate business by
            providing information of interest our customers and the users of the
            Site.
          </p>
          <h3 className="mb-6 text-lg text-[#00ae41]">
            NOTICE TO CALIFORNIA RESIDENTS
          </h3>
          <p>
            Except as expressly identified below, we do not disclose a user’s
            personal data to any third-party for such third-party’s direct
            marketing purposes. Thus, under California Civil Code Sections
            1798.80-1798.84, Owner has no obligation to a California resident to
            provide further information regarding Owner’s use of the California
            resident’s personal data. Any inquiries regarding the Terms of Use
            or, specifically, this Privacy Policy can be directed to the contact
            identified below.
          </p>

          <h3 className="mb-6 text-lg text-[#00ae41]">
            NOTICE TO UTAH RESIDENTS
          </h3>
          <p>
            Except as expressly identified below, we do not disclose a user’s
            personal data to any third-party for such third-party’s direct
            marketing purposes
          </p>
          <h3 className="mb-6 text-lg text-[#00ae41]">
            WHAT THIS PRIVACY POLICY COVERS
          </h3>
          <p>
            This Privacy Policy covers our treatment of Personal Data that we
            collect through your use of the Site and when you use Services
            provided on the Site. This policy does not apply to the practices of
            companies that we do not own and/or control or to people that we do
            not employ or manage.
          </p>

          <h3 className="mb-6 text-lg text-[#00ae41]">
            INFORMATION COLLECTION AND USE
          </h3>
          <p>
            Through your use of the Services, we may collect the following
            “Personal Data” from you if you choose to provide it, including:
          </p>
          <ul>
            <li>
              Your name, email, telephone number, and/or residence information
              (“Contact Information”).
            </li>
            <li>
              Pages and products viewed, ads that you clicked on, emails from us
              that you opened, browser type, operating system, IP address and
              device information, your mobile operating system (OS), a mobile
              device identifier embedded by us, or other commonly used mobile
              device identifier if you access the Site on a mobile device
              (“Analytical Information”).
            </li>
            <li>
              We may also collect publicly available information about you from
              third-party sources, such as the postal service for shipping
              address verification.
            </li>
          </ul>
          <h3 className="mb-6 text-lg text-[#00ae41]">
            HOW YOUR PERSONAL DATA MAY BE USED.
          </h3>
          <p>
            We may use Analytical Information to improve the performance or
            layout of our website; to develop new services and ideas; to target
            advertisements to you on the websites of others, and to better
            administer and troubleshoot our systems.
          </p>

          <p>
            We may use Contact Information and other Personal Data to provide
            you the Services on the Site; to evaluate and improve the Services;
            to fulfill your requests for information; and to contact you about
            Verde Valle products or services and those of our affiliates, based
            on the preferences you have indicated.
          </p>

          <p>
            We provide you the opportunity to consent to receive commercial
            email from us related to the Services or information that we deem
            you may be interested in when you seek more information from us. We
            will give you the opportunity to "opt out" of receiving any
            unsolicited information from us or to limit the unsolicited
            information you receive from us to information regarding the
            Services or information you specifically request or information we
            determine you may find useful as a result of your use of the Site.
          </p>

          <h3 className="mb-6 text-lg text-[#00ae41]">
            INFORMATION SHARING AND DISCLOSURE
          </h3>
          <p>
            Except as otherwise described in this Privacy Policy, we will not
            share your Personal Data with any other person or company. We will
            share Personal Data about you to other companies or people when:
          </p>
          <ul>
            <li>
              We need to share your information to provide a product or service
              you have requested;
            </li>
            <li>
              We need to send the information to companies who work on behalf of
              Verde Valle to provide a product or service to you (unless we tell
              you differently, these companies do not have any right to use the
              Personal Data we provide to them beyond what is necessary to
              assist us);
            </li>
            <li>
              To enforce our agreement with you for Products and Services;
            </li>
            <li>
              To protect the security or integrity of our operations and IT
              infrastructure;
            </li>
            <li>
              As required to respond to or initiate subpoenas, court orders, or
              legal process;
            </li>
            <li>
              In the event of a corporate sale, merger, acquisition, or similar
              event; and
            </li>
            <li>
              In connection with the sale of any aggregated anonymized data
              based on the Personal Data you provide us.
            </li>
          </ul>

          <h3 className="mb-6 text-lg text-[#00ae41]">USE OF COOKIES</h3>

          <p>
            We use “cookies,” a small text file transferred to your device,
            along with similar technologies (e.g., internet tag technologies,
            web beacons, embedded scripts) to help provide you a better, more
            personalized user experience. These technologies are used to:
          </p>

          <ul>
            <li>Make the user experience more user friendly;</li>
            <li>
              Remember your preferences (e.g., browsing language, usage
              preferences); or
            </li>
            <li>
              Help us understand and improve how visitors use our website,
              including which of our pages and products are viewed most
              frequently.
            </li>
          </ul>

          <p>
            The Options/Settings section of most internet browsers will tell you
            how to manage cookies and other technologies that may be transferred
            to your device, including how to disable such technologies. You can
            disable our cookies or all cookies through your browser settings.
            Please be advised that disabling cookies through either method may
            impact many of our website’ features.
          </p>

          <p>
            The use of third-party cookies is not covered by our Privacy Policy.
            We do not have access or control over these cookies. If you continue
            to use our website, we will assume you agree to the use of these
            cookies.
          </p>

          <h3 id="cookies" className="mb-6 text-lg text-[#00ae41]">
            DO NOT TRACK
          </h3>
          <p>
            Some internet browsers incorporate a “Do Not Track” feature that
            signals to websites you visit that you do not want to have your
            online activity tracked. Given that there is not a uniform way that
            browsers communicate the “Do Not Track” signal, the Site does not
            currently interpret, respond to or alter its practices when it
            receives “Do Not Track” signals.
          </p>

          <h3 className="mb-6 text-lg text-[#00ae41]">
            ABILITY TO REVIEW AND EDIT YOUR INFORMATION
          </h3>
          <p>
            You may review and approve the Contact Information about you that
            was stored in our database and obtained through your use of the
            Site. Upon your written request, we will remove that information
            from our database or change or correct personal data that you state
            is erroneous within applicable regulatory or other legal
            requirements. You should understand, however, that information about
            you in our database might come from a number of sources.
          </p>

          <h3 className="mb-6 text-lg text-[#00ae41]">SECURITY</h3>
          <p>
            We will maintain reasonable safeguards to ensure the security,
            integrity and privacy of your Personal Data. However, no electronic
            storage method or data transmission over the Internet can be
            guaranteed to be 100% secure. As a result, given the electronic
            nature of the Internet, you accept the inherent risks in doing so,
            including, but not limited to, the security risks of interception,
            unauthorized access, the risks of corruption and the risks of
            viruses or other harmful devices.
          </p>

          <h3 className="mb-6 text-lg text-[#00ae41]">
            COMMITMENT TO CHILDREN’S PRIVACY
          </h3>
          <p>
            In compliance with the Children's Online Privacy Protection Act, 15
            U.S.C., §§ 6501-06 and 16 C.F.R., §§ 312.1-312.12, the Site does
            collect information from children under 13 years of age, and we do
            not intentionally collect information from persons under eighteen
            (18) years of age. Use of the Site is limited to users that are
            thirteen (13) years of age and older. By using the Site, you
            represent that you are thirteen (13) years of age or older.
          </p>

          <h3 className="mb-6 text-lg text-[#00ae41]">
            CHANGES TO THIS PRIVACY POLICY
          </h3>
          <p>
            We reserve the right to change, modify or otherwise update this
            policy at any time. These changes or updates will be effective
            immediately. We may provide you notice of such changes when they are
            material, such notice may be given by posting on the Site, by
            electronic or conventional mail or by any other means by which you
            obtain notice of the changes or updates.
          </p>
          <h3 className="mb-6 text-lg text-[#00ae41]">
            POLICIES OF OTHER WEBSITES
          </h3>
          <p>
            The Site may contain links to third-party websites not owned or
            controlled by Verde Valle. Verde Valle is not responsible for the
            privacy policies of any third-party websites which user may access
            through a third party link. Further, these third-party websites may
            have privacy policies that differ from this Privacy Policy. Verde
            Valle disclaims all responsibility for the privacy practices of such
            other third-party websites. You should read the privacy policies of
            each third-party website you visit to determine what information
            each third-party website may be collecting about you and how they
            intend to use such information.
          </p>
          <h3 className="mb-6 text-lg text-[#00ae41]">QUESTIONS</h3>
          <p>
            If you have any questions or comments about the Privacy Policy or
            this Site, please contact us at:PRODUCTOS VERDE VALLE S.A. DE C.V.
            AV. VALLARTA # 5683 SANTA MARIA DEL PUEBLITO ZAPOPAN, JALISCO,
            MÉXICO C.P. 45010 (011) (52) 33 3540-2200
          </p>
        </div>
      </section>
      <NavegableFooter />
    </>
  );
};

export default PrivacyPolicy;
