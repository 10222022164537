import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass as Search } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF as Facebook,
  faInstagram as Instagram,
} from '@fortawesome/free-brands-svg-icons';

const socialMediaButtons = [
  {
    icon: Facebook,
    link: 'https://www.facebook.com/IsadoraMexicanFood/',
  },
  { icon: Instagram, link: 'https://www.instagram.com/isadoramexicanfood/' },
  { icon: Search },
];

const SocialMediaNav = () => {
  return (
    <nav>
      <ul className='flex justify-center gap-4'>
        {socialMediaButtons.slice(0, 2).map((button, i) => (
          <li
            key={i}
            className='my-auto h-10 w-10 rounded-full bg-white py-2 text-center'
          >
            <a
              href={button.link}
              target='_blank'
              rel='noopener noreferrer'
              className='block'
            >
              <FontAwesomeIcon
                icon={button.icon}
                className=' fa-lg text-mf-orange-200'
              />
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SocialMediaNav;
