import React from "react";
import { WoodBackground, Pouch } from "../assets/images/";

const BannerFooter = () => {
    return (
        <section className="bg-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${WoodBackground})`}}>
            <div className="container mx-auto px-5 relative flex flex-col-reverse lg:flex-row max-w-screen-xl">
                <div className="lg:w-1/2">
                    <img src={Pouch} 
                        alt="Isadora Refried Beans" 
                        className="mt-16 lg:-mt-14 max-w-[80%] md:max-w-[600px] md:max-h-[570px] h-auto w-auto mx-auto lg:ml-0"/>
                </div>
                <div className="lg:w-1/2 flex flex-col justify-center text-center pt-10 lg:pt-0">
                    <h2>
                        <span className="font-kellog text-7xl md:text-9xl text-white drop-shadow-bannerFooter">
                            Want 
                            <span className="text-6xl md:text-8xl"> to</span>
                        </span><br/> 
                        <span className="font-oswald text-6xl md:text-8xl text-mf-yellow-100 drop-shadow-bannerFooter">complete</span><br/> 
                        <span className="font-kellog text-6xl md:text-8xl text-mf-yellow-100 drop-shadow-bannerFooter">your meals?</span>
                    </h2>
                    <h3 className="text-xl md:text-2xl text-white font-gothamBold mt-4 drop-shadow-bannerFooter">Visit our Isadora Beans site.</h3>
                    <a href="https://www.isadorabeans.com" 
                        target="_blank" 
                        rel="noreferrer"
                        className="bg-mf-orange-200 px-3 font-bold text-white uppercase duration-200 text-lg md:text-2xl hover:scale-125 max-w-[360px] mx-auto mt-5">
                            Visit Now
                    </a>
                </div>
            </div>
        </section>
    );
};

export default BannerFooter;