import PropTypes from 'prop-types';
import styles from './styles';

const RecipeMainTitle = ({ headline, color }) => {
  return (
    <>
      <h3 className={styles.mainTitle(color)} id={headline+"RecipeSectionTitle"}>{headline} Recipes</h3>
      <div
        className={styles.line}
        role='img'
        aria-label='Brown dividing line'
      ></div>
    </>
  );
};

RecipeMainTitle.propTypes = {
  headline: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default RecipeMainTitle;
