import PropTypes from 'prop-types';
import styles from './styles';
import { RecipeMainTitle, RecipeSubTitle, RecipeList } from './';

const RecipePorkInGreenSalsaTaco = ({ data }) => {
  return (
    <article className={`${styles.article} ${styles.borderBottom}`}>
      <div className='text-center'>
        <RecipeMainTitle headline={data.title} color={data.color} />
        <RecipeSubTitle headline={data.recipes[0].title} id={data.recipes[0].id} m='lg:mb-[7rem]' />
      </div>
      <div className='justify-start gap-8 2xl:justify-end gap-8 flex flex-wrap-reverse lg:flex-nowrap lg:flex mt-10 mb-14'>
                        
        <div className='lg:-translate-x-[30%] xl:flex-shrink-0 lg:translate-x-0'>
          
          {['Ingredients', 'Directions'].map((title, i) => (
            <RecipeList
              title={title}
              color={data.color}
              list={data.recipes[0].recipe[1][title.toLowerCase()]}
              key={i}
            />
          ))}
        </div>

        <img
          src={data.recipes[0].img}
          alt={data.recipes[0].alt + ' recipe'}
          className={`${styles.moleChickenTacoImgRight()}`}
        />

      </div>


      <div className='gap-x-[10%] lg:flex lg:-translate-x-[30%] xl:flex-shrink-0 lg:translate-x-0 mt-10'>

      {data.recipes[0].flag && (
            <span className={styles.flag(data.bgColor, 'left-0')}>
              {data.recipes[0].flag}
            </span>
          )}


        {['Ingredients', 'Directions'].map((title, i) => (
          <RecipeList
            title={title}
            color={data.color}
            list={data.recipes[0].recipe[0][title.toLowerCase()]}
            basis={`lg:basis-[${!i ? '20' : '70'}%] `}
            key={i}
            borderRight={!i ? 'after:-right-[25%] ' : null}
          />
        ))}
      </div>
    </article>
  );
};

RecipePorkInGreenSalsaTaco.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RecipePorkInGreenSalsaTaco;
