import React from 'react';
import { favicon } from '../assets/images';
import { Helmet } from 'react-helmet';
import { NavegableHeader, NavegableFooter } from '../components';

const FAQ = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Notice Of Privacy | Mexican Foods | Isadora Beans</title>
        <link rel="canonical" href="https://mexicanfood.com/" />
        <meta
          name="description"
          content="México’s #1 Beans in a pouch. Isadora Beans are the tastiest, freshly made and vacuum-packed without preservatives. Try them on their own or add your personal touch."
        />
        <meta
          name="keywords"
          content="beans, mexico, isadora beans, food, fresh food, tasty, mexican food"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href={favicon} />
      </Helmet>
      <NavegableHeader />
      <div id="faq-section-custom">
        <section id="faqs-bg" className="mt-16 pt-6">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center">
              <h2 className="text-center font-gothamBold text-2xl uppercase text-mf-brown-200 md:text-4xl">
                FAQS
              </h2>
            </div>
          </div>
          <div className="row mb-3 mt-6">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <p className="gotham-hearfrom mb-3 text-center">
                <strong className="text-center font-gothamBold text-2xl uppercase text-mf-brown-200 md:text-2xl">
                  WHAT IS A POUCH?
                </strong>
                <br />
                <span>
                  This is the name given to the bag-type packaging designed with
                  the highest technology, made with a lamination suitable for
                  sterilization, which guarantees safety and better preserves
                  the flavor of our beans.
                </span>
              </p>
              <br />
              <br />

              <p className="gotham-hearfrom mb-3 text-center">
                <strong className="text-center font-gothamBold text-2xl uppercase text-mf-brown-200 md:text-2xl">
                  DO ISADORA BEANS HAVE PRESERVATIVES?
                </strong>
                <br />
                <span className="text-mf-brown-200">
                  Thanks to our vacuum packaging process, it is not necessary to
                  add any type of preservative or additive.
                </span>
              </p>
              <br />
              <br />

              <p className="gotham-hearfrom mb-3 text-center">
                <strong className="text-center font-gothamBold text-2xl uppercase text-mf-brown-200 md:text-2xl">
                  IS IT TRUE THAT ISADORA BEANS DO NOT CONTAIN CHOLESTEROL?
                </strong>
                <br />
                <span>
                  It's true! Isadora beans contain no cholesterol or trans fatty
                  acids.
                </span>
              </p>
              <br />
              <br />

              <p className="gotham-hearfrom mb-3 text-center">
                <strong className="text-center font-gothamBold text-2xl uppercase text-mf-brown-200 md:text-2xl">
                  CAN I EAT ISADORA BEANS IF I WANT TO TAKE CARE OF THE LINE?
                </strong>
                <br />
                <span>
                  Of course! Isadora has a high fiber content, which will help
                  you feel satisfied for longer. Plus, we have low-fat refried
                  beans.
                </span>
              </p>
              <br />
              <br />

              <p className="gotham-hearfrom mb-3 text-center">
                <strong className="text-center font-gothamBold text-2xl uppercase text-mf-brown-200 md:text-2xl">
                  WHAT ARE THE BENEFITS OF CONSUMING ISADORA BEANS?
                </strong>
                <br />
                <span>
                  Isadora beans taste like home made, plus they're nutritious
                  and healthy.
                </span>
              </p>
              <br />
              <br />

              <p className="gotham-hearfrom mb-3 text-center">
                <strong className="text-center font-gothamBold text-2xl uppercase text-mf-brown-200 md:text-2xl">
                  HOW LONG DO ISADORA BEANS LAST ONCE OPENED?
                </strong>
                <br />
                <span>
                  Like home-made beans, once opened, Isadora beans must remain
                  refrigerated and are recommended for consumption within 7
                  days.
                </span>
              </p>
              <br />
              <br />

              <p className="gotham-hearfrom mb-3 text-center">
                <strong className="text-center font-gothamBold text-2xl uppercase text-mf-brown-200 md:text-2xl">
                  CAN I MAKE ENTIRE MEALS WITH ISADORA BEANS?
                </strong>
                <br />
                <span>
                  ¡Por supuesto! Con Isadora puedes echar a volar tu imaginación
                  y preparar platillos deliciosos.
                </span>
              </p>
              <br />
              <br />

              <p className="gotham-hearfrom mb-3 text-center">
                <strong className="text-center font-gothamBold text-2xl uppercase text-mf-brown-200 md:text-2xl">
                  HOW CAN I CONTACT ISADORA?
                </strong>
                <br />
                <span>
                  We want to know your questions and comments.{' '}
                  <a to="/contact"> Write us here!</a>
                </span>
              </p>
              <br />
              <br />
            </div>
          </div>
          <div className="row mt-5 mb-5">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center"></div>
          </div>
        </section>
      </div>
      <NavegableFooter />
    </>
  );
};

export default FAQ;
