import React from "react";
import { Logo } from "../assets/images/";
import { Link } from 'react-router-dom';

const scrollTo = (e, bookmark) => {
  e.preventDefault();
  const titleElement =  document.getElementById(bookmark);
  titleElement.scrollIntoView({ behavior: 'smooth' });
};

const Footer = ({
  aboutUsRef,
  productsRef,
  recipesRef,
  WTBRef,
  contactRef,
}) => {
  const sectionData = [
    { name: "About Us", ref: aboutUsRef, bookmark: "about-us-section-bookmark" },
    { name: "Products", ref: productsRef, bookmark: "BarbacoaProductTitle" },
    { name: "Recipes", ref: recipesRef, bookmark: "BarbacoaRecipeSectionTitle" },
    { name: "Where to Buy", ref: WTBRef, bookmark: "where-to-buy" },
    { name: "Contact", ref: contactRef, bookmark: "contact-section-bookmark" },
  ];

  const footerElems = [];
  const footerElemsStyle = "font-sans text-lg font-semibold";

  sectionData.map((elem) =>
    footerElems.push(
      <button
        key={elem.name}
        onClick={(e) => scrollTo(e, elem.bookmark)}
        className={footerElemsStyle}
      >
        {elem.name}
      </button>
    )
  );

  return (
    <footer>
      <div className="bg-gradient-to-b from-mf-orange-200 via-mf-orange-200 to-mf-orange-300 pt-6 pb-6 md:pt-16 md:pb-11">
        <div className="container mx-auto max-w-screen-xl px-5">
          <div className="flex flex-wrap justify-center align-middle lg:flex-nowrap lg:justify-between">
            <div className="w-full lg:w-3/5">
              <img
                src={Logo}
                alt="Isadora logo"
                className="mx-auto w-4/6 drop-shadow-logo"
              />
            </div>
            <div className="mt-8 flex flex-col justify-center self-center text-white md:mt-5 md:w-full md:flex-row lg:mt-0 lg:w-2/5 lg:justify-start">
              <div className="flex flex-col items-center md:items-start md-custom-pr--10 md:text-left">
                <h2 className="mb-2 text-center font-montserrat text-2xl font-semibold uppercase md:text-left">
                  Menu
                </h2>
                <div className="inline-flex flex-col md:items-start">
                  {footerElems}
                </div>
              </div>
              <div className="mt-6 text-center md:mt-0 md:text-left">
                <h2 className="mb-2 font-montserrat text-2xl font-semibold uppercase">
                  Products
                </h2>
                <ul className="flex flex-col gap-2">
                  <li className="font-sans text-lg font-semibold">
                    <a href="#BarbacoaProductTitle">Mexican Style Seasoned Beef</a>
                  </li>
                  <li className="font-sans text-lg font-semibold">
                    <a href="#MoleProductTitle">Chicken with Mole Sauce</a>
                  </li>
                  <li className="font-sans text-lg font-semibold">
                    <a href="#PorkProductTitle">Pork in Green Salsa</a>
                  </li>
                  <li className="font-sans text-lg font-semibold">
                    <a href="#CornProductTitle">Mexican Rice with Corn</a>
                  </li>
                  <li className="font-sans text-lg font-semibold">
                    <a href="#TomatoProductTitle">Mexican Rice with Tomato</a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-mf-orange-200 pt-4 pb-4 md:pt-2 md:pb-2">
        <div className="container mx-auto max-w-screen-xl px-5">
          <div className="flex flex-col-reverse justify-center text-center align-middle text-xl font-medium text-white md:flex-row">
            <span className="px-2">2022 Copyright Isadora Beans</span>
            <span className="hidden md:inline">|</span>
            <Link to="/privacy" className="px-2 text-white">
              Privacy Policy
            </Link>
            <span className="hidden md:inline">|</span>
            <Link to="/faqs" className="px-2 text-white">
              FAQ's
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
