import {
    faTwitter as Twitter,
    faFacebookF as Facebook,
    faInstagram as Instagram,
    faPinterestP as Pinterest,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const ShareNav = styled.ul`
    width: 100px;
    margin-bottom: 0px;
`;

const ShareNavNews = (props) => {
    const fullUrl = window.location.href;
    return (
        <ShareNav className='d-flex justify-content-between ps-3 alx-cont-redes-news'>
            <li className='circle-btn-orange'>
                <a
                    href={'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href}
                    aria-label={`go to Facebook`}
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    <FontAwesomeIcon icon={Facebook} className='fa-lg' />
                </a>
            </li>
            <li className='circle-btn-orange'>
                <a
                    href={'https://twitter.com/intent/tweet?url=' + window.location.href}
                    aria-label={`go to Twitter`}
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    <FontAwesomeIcon icon={Twitter} className='fa-lg' />
                </a>
            </li>
        </ShareNav>
    );
};

export default ShareNavNews;
