import PropTypes from 'prop-types';
import styles from './styles';
import { RecipeMainTitle, RecipeSubTitle, RecipeList } from './';

const RecipeBarbacoaBeefTaco = ({ data }) => {
  return (
    <article className={`${styles.article} ${styles.borderBottomMed}`}>
      <div className='text-center'>
        <RecipeMainTitle headline={data.title} color={data.color} />
        <RecipeSubTitle headline={data.recipes[0].title} id={data.recipes[0].id} m='lg:mb-10' />
      </div>
      <div className='justify-start 2xl:justify-end gap-8 flex flex-wrap-reverse lg:flex-nowrap lg:flex mt-10 mb-14'>
        
        <div className='lg:-translate-x-[30%] xl:flex-shrink-0 lg:translate-x-0'>
          {['Ingredients', 'Directions'].map((title, i) => (
            <RecipeList
              title={title}
              color={data.color}
              list={data.recipes[0].recipe[0][title.toLowerCase()]}
              key={i}
            />
          ))}
        </div>

        <img
          src={data.recipes[0].img}
          alt={data.recipes[0].alt + ' recipe'}
          className={`${styles.moleChickenTacoImgRight()}`}
        />
      </div>
    </article>
  );
};

RecipeBarbacoaBeefTaco.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RecipeBarbacoaBeefTaco;
