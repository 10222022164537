const styles = {
  section:
    'text-mf-brown-300 bg-recipesTexture bg-auto lg:bg-contain bg-center py-5 overflow-hidden',
  carousel:
    'flex transition duration-500 ease-in-out lg:block lg:translate-x-0 lg:transition-none',
  article:
    'relative mx-auto w-[90%] max-w-[1440px] mt-6 mb-10 lg:mt-14 lg:mb-8',
  barbacoa: 'translate-x-0',
  mole: '-translate-x-[0%]',
  pork: '-translate-x-[0%]',
  tomato: '-translate-x-[0%]',
  corn: '-translate-x-[0%]',
  recipesSelector: 'mx-auto w-[90%] flex justify-center lg:hidden alx-padre-botones-recetas-filtrado',
  recipesBtn: 'text-white font-futuraBold uppercase p-3',
  mainTitle: (color) =>
    `text-uppercase text-center font-futuraBold text-[24px] uppercase md:text-[40px] ${color}`,
  line: 'mx-auto mt-3 h-[6px] w-[90%] max-w-[226px] bg-brownLine bg-contain bg-center bg-no-repeat',
  subTitle: (m) =>
    `text-uppercase my-8 mx-auto pb-5 inline-block text-center font-montserrat text-[20px] font-bold uppercase leading-none md:mt-[3.5rem] ${m} md:text-[35px] relative after:content-[""] after:absolute after:h-1 after:max-w-[220px] after:bottom-0 after:bg-mf-brown-200 after:w-full after:left-[50%] after:-translate-x-1/2`,
  generalImg: 'mx-auto lg:mx-0 w-[70%] my-4 object-contain',
  barbacoaBeefTacoImg() {
    return `${this.generalImg} lg:top-[5rem] xl:top-[3rem] right-0 max-w-[689px] lg:w-[45vw] lg:translate-x-[30%] lg:absolute`;
  },
  barbacoaBeefTortillaFlautasImg() {
    return `${this.generalImg} max-w-[650px] lg:w-[60%] 2xl:translate-x-0 w-[80%] lg:-translate-x-[30%]`;
  },
  moleChickenTacoImg() {
    return `${this.barbacoaBeefTortillaFlautasImg()} lg:-mt-[4rem] 2xl:-mt-[2rem] max-w-[755px] lg:-translate-x-[22%]`;
  },
  ////// Agregado PP
  moleChickenTacoImgRight(){
    return `${this.barbacoaBeefTortillaFlautasImg()} lg:-mt-[4rem] lg:-mt-[2rem] max-w-[755px] lg:-translate-x-[-12%]`;
  },
  moleChickenSliderImgLeft() {
    return `${this.generalImg} max-w-[505px] lg:realtive mx-auto left-0 lg:top-0 xl:-top-[5rem] lg:w-1/3`;
  },
  articleMoleChickenSliders() {
    return `${this.article} left-1`;
  },
  grilledChickenBreast(){
    return `${this.barbacoaBeefTacoImg()} `;
  },
  ///////
  moleChickenSliderImg() {
    return `${this.generalImg} max-w-[505px] lg:absolute right-0 lg:top-2 xl:-top-[5rem] lg:w-[30vw]`;
  },
  porkInGreenSalsaTostada() {
    return `${this.generalImg} max-w-[721px] basis-[60%] lg:-mt-7`;
  },
  /// PP
  porkInGreenSalsaTostadaLeft() {
    return `${this.generalImg} max-w-[621px] 2xl:max-w-[721px]  lg:-mt-7 2xl:translate-x-0 w-[80%] lg:-translate-x-[30%]`;
  },
  ///
  borderRight: (r) =>
    `after:hidden lg:after:block after:content-[''] after:absolute after:h-[50%] after:w-1 ${r} after:bg-mf-brown-200 after:-translate-y-1/2 after:top-[50%]`,
  borderBottom:
    "after:content-[''] after:absolute after:h-1 after:w-[80%] after:max-w-[370px] after:bg-mf-brown-200 after:-bottom-10 after:lg:-bottom-8 after:left-[50%] after:-translate-x-1/2",

  //// Agregado PP
  borderBottomMed:
  "after:content-[''] after:absolute after:h-1 after:w-[80%] after:max-w-[370px] after:bg-mf-brown-200 after:-bottom-10  after:left-[50%] after:-translate-x-1/2",
  marginTop:
  'mt-10',
  borderBottomRiceTomato(){
    return `${this.borderBottom} after:h-0 lg:after:h-1`;
  },
 //////
  flag: (bgColor, left) =>
    `align-center flex mb-3 py-3 px-2.5 text-white ${bgColor} rounded-md font-alternate2 text-xl 1.5xl:text-[22px] leading-none w-fit lg:absolute top-[-4.5rem] ${left}`,

  barbacoaver: 'barbacoa-ver',
  molever: 'mole-ver',
  porkver: 'pork-ver',
  tomatover: 'tomato-ver',
  cornver: 'corn-ver', 
};

export default styles;
