import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home, FAQ, PrivacyPolicy, Redirect, News, SingleNews, ResultsNews } from './views';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/faqs' element={<FAQ />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
          <Route path='/getitonyourstore' element={<Redirect />} />
          <Route path='*' element={<Home />} />
          <Route path='/news' element={<News />} />
          <Route path='/news/:slug' element={<SingleNews />} />
          <Route path='/news/results/:category/:date/:search' element={<ResultsNews />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
