import React, { useRef, useEffect } from 'react';
import {
  Header,
  WhereToBuy,
  Contact,
  Footer,
  BannerFooter,
  FollowUs,
  Hero,
  Products,
  AboutUs,
  Recipes,
  RecipesAllSection,
} from '../components';
import StoreModal from '../components/StoreModal/StoreModal';
import { favicon } from '../assets/images';
import { Helmet } from 'react-helmet';

const Home = () => {
  const contactRef = useRef(null);
  const WTBRef = useRef(null);
  const recipesRef = useRef(null);
  const aboutUsRef = useRef(null);
  const productsRef = useRef(null);
  const imageWhereToBuy = useRef(null);

  useEffect(() => {
    if (window.location.hash === '#where-to-buy') {
      setTimeout(() => {
        window.scrollTo({
          top: WTBRef.current.getBoundingClientRect().top + window.scrollY,
          behavior: 'smooth',
        });
      }, 1000);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Mexican Foods | Isadora Beans | Mexico is just 2 minutes away
        </title>
        <link rel="canonical" href="https://www.isadoramexicanfood.com/" />
        <meta
          name="description"
          content="México’s #1 Beans in a pouch. Isadora Beans are the tastiest, freshly made and vacuum-packed without preservatives. Try them on their own or add your personal touch."
        />
        <meta
          name="keywords"
          content="beans, mexico, isadora beans, food, fresh food, tasty, mexican food"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          property="og:title"
          content="Mexican Foods | Isadora Beans | Mexico is just 2 minutes away"
        />
        <meta property="og:url" content="https://www.isadoramexicanfood.com/" />
        <meta property="og:image" content={favicon} />
        <link rel="icon" href={favicon} />
      </Helmet>
      <Header
        contactRef={contactRef}
        WTBRef={WTBRef}
        recipesRef={recipesRef}
        aboutUsRef={aboutUsRef}
        productsRef={productsRef}
      />
      <Hero />
      <AboutUs ref={aboutUsRef} />
      <Products ref={productsRef} />
      <Recipes ref={recipesRef} />
      <RecipesAllSection />
      <WhereToBuy ref={WTBRef} imageRef={imageWhereToBuy} />
      <Contact ref={contactRef} />
      <FollowUs />
      <BannerFooter />
      <Footer
        contactRef={contactRef}
        WTBRef={WTBRef}
        recipesRef={recipesRef}
        aboutUsRef={aboutUsRef}
        productsRef={productsRef}
      />
      <StoreModal />
    </div>
  );
};

export default Home;
