import PropTypes from 'prop-types';
import styles from './styles';
import { RecipeSubTitle, RecipeList } from './';

const RecipeMoleChickenSliders = ({ data }) => {
  return (
    <article className={`${styles.articleMoleChickenSliders()}`}>
      <div className='text-center flex justify-between flex-wrap'>
      
      <RecipeSubTitle headline={data.recipes[1].title} id={data.recipes[1].id} m='lock lg:hidden w-full' />   
      <img
          src={data.recipes[1].img}
          alt={data.recipes[1].alt + ' recipe'}
          className={`${styles.moleChickenSliderImgLeft()}`}
        />
        <RecipeSubTitle headline={data.recipes[1].title} id={data.recipes[1].id} m='lg:mb-[5rem] lg:mx-auto lg:w-1/3 lg:mb-auto lg:my-auto hidden lg:block lg:-top-20' />   

        <div className="flex-col lg:m-auto lg:w-1/3">
              <div>
                <RecipeList
                  title='Ingredients'
                  color={data.color}
                  list={data.recipes[1].recipe[0].ingredients}
                />
              </div>
              <div>
                <RecipeList
                  title='Directions'
                  color={data.color}
                  list={data.recipes[1].recipe[0].directions}
                  basis='lg:basis-[33%] '
                  //borderRight='after:right-[-12px] after:xl:right-4'
                />        
              </div>
           </div>     
      </div>

      <div className='relative lg:flex lg:basis-[66%] mt-20'>
          {data.recipes[1].flag && (
            <span className={styles.flag(data.bgColor, 'left-0')}>
              {data.recipes[1].flag}
            </span>
          )}
          {['Ingredients', 'Directions'].map((title, i) => (
            <RecipeList
              title={title}
              color={data.color}
              list={data.recipes[1].recipe[1][title.toLowerCase()]}
              basis={`lg:basis-[46%] xl:basis-[40%] xl:basis-[${!i ? '4' : '5'}5%] `}
              key={i}
              
            />
          ))}
          
        </div>
     
       
    </article>
  );
};

RecipeMoleChickenSliders.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RecipeMoleChickenSliders;
